// ErrorPage.js
import React from "react";

const ErrorPage = ({ errorMessage }) => {
  return (
    <div className="bg-gray-700 h-screen text-white place-content-center items-center text-center  grid">
      <h1 className="text-8xl">Error</h1>
      <p className="text-2xl">{errorMessage}</p>
    </div>
  );
};

export default ErrorPage;
