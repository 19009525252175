import React, { useState, useEffect, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { getDatabase, ref, onValue } from 'firebase/database';
import { useLocation } from 'react-router-dom';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyA42elJs5jrWKOq5SBXP4PcRPeUqe1S68U",
  authDomain: "test-10347.firebaseapp.com",
  databaseURL: "https://test-10347-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "test-10347",
  storageBucket: "test-10347.appspot.com",
  messagingSenderId: "30130834791",
  appId: "1:30130834791:web:13a2bafcb8e9bbab36f3c2"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const previousDataRef = useRef({});
  const location = useLocation();

  const notify = (tableNumber, position) => {
    toast(<ToastDisplay tableNumber={tableNumber} position={position} />);
  };

  function ToastDisplay({ tableNumber, position }) {
    return (
      <div>
        <p><b>Table Number: {tableNumber}</b></p>
        <p>Position: {position}</p>
      </div>
    );
  }

  useEffect(() => {
    // Only run the notification logic if the current route is `/`
    if (location.pathname === '/') {
      const db = getDatabase();
      const ordersRef = ref(db, 'orders');

      onValue(ordersRef, (snapshot) => {
        const newData = snapshot.val();
        const previousData = previousDataRef.current;

        Object.keys(newData).forEach(orderId => {
          const data = newData[orderId];
          const previousOrderData = previousData[orderId];

          // Check if `callTimes` exists and compare with previous data
          if (data.callTimes) {
            const previousCallTimes = previousOrderData ? previousOrderData.callTimes || [] : [];
            const currentCallTimes = data.callTimes || [];

            // Find new entries in `callTimes`
            const newCallTimes = currentCallTimes.filter(time => !previousCallTimes.includes(time));
            
            if (newCallTimes.length > 0) {
              setNotifications(prev => [
                ...prev,
                { tableNumber: data.tableNumber, position: data.position }
              ]);
              notify(data.tableNumber, data.position);
            }
          }
        });

        // Update ref with the latest data
        previousDataRef.current = newData;
      });
    }
  }, [location.pathname]);

  return location.pathname === '/' ? <Toaster /> : null;
};

export default Notification;
