import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import Waiter from './pages/Waiters';
import SingleTables from './pages/SingleTables';
import CustomerForm from './pages/Settings';
import { firebaseConfig, auth } from './firebaseConfig';
import Register from './components/Auth/Register';
import Login from './components/Auth/Login';
import { onAuthStateChanged } from 'firebase/auth';
import './index.css';
import Notification from './firebaseNotifications/Notification';

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);
const messaging = getMessaging(firebaseApp);

const App = () => {
  const [user, setUser] = useState(null);
  const [authLoaded, setAuthLoaded] = useState(false);

  useEffect(() => {
    // Request notification permission
    const requestPermission = async () => {
      try {
        const token = await getToken(messaging, {
          vapidKey: 'BOjnlWe6--Tvy3KRVj8bgNc_iBpe9ObtNY3PCDYJbXoDx3WUSqTHmxB6p-xlqZdEyI_ugqyC7fUK-d-bFrQbMYk'
        });
        if (token) {
          console.log('FCM Token:', token);
          // Optionally send the token to your server
        } else {
          console.log('No registration token available.');
        }
      } catch (error) {
        console.error('Error getting token:', error);
      }
    };

    requestPermission();

    // Handle foreground messages
    onMessage(messaging, (payload) => {
      console.log('Message received:', payload);
      const { title, body } = payload.notification;
      if (title && body) {
        new Notification(title, { body });
      }
    });

    // Check if the user is logged in
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setAuthLoaded(true);
    });

    return () => unsubscribe();
  }, []);

  if (!authLoaded) {
    return <div className='dark:bg-gray-700 h-screen text-white place-content-center items-center flex'>Loading...</div>;
  }

  return (
    <Router>
      <Notification />
      <Routes>
        {!user && (
          <>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
        <Route path="/table/:tableNumber" element={<SingleTables />} />
        <Route path="/orders/:tableNumberHash" element={<SingleTables />} />
        {user && (
          <>
            <Route path="/settings" element={<CustomerForm database={database} />} />
            <Route path="/" element={<Waiter />} />
          </>
        )}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
