// Settings.js
import React, { useState, useEffect } from "react";
import { ref, get, update, set, push, remove } from "firebase/database";
import Header from "../../src/components/Header/Header";
import md5 from "md5";

const Settings = ({ onSubmit, database }) => {
  const [tableNumber, setTableNumber] = useState("");
  const [position, setPosition] = useState("");
  const [newPosition, setNewPosition] = useState("");
  const [positions, setPositions] = useState([]);
  const [newUserID] = useState("your-user-id");
  const [orders, setOrders] = useState([]);
  const [editOrderKey, setEditOrderKey] = useState(null); // New state to track the order being edited
  const [users, setUsers] = useState([]);
  const [selectedPositionColor, setSelectedPositionColor] = useState("#000000"); // Default color
  const [tableExistsMessage, setTableExistsMessage] = useState("");
  const [orderPositionsValue, setOrderPositionsValue] = useState('');
  const [batchStart, setBatchStart] = useState("");
  const [batchEnd, setBatchEnd] = useState("");
  const [batchPosition, setBatchPosition] = useState("");
  useEffect(() => {
    const positionsRef = ref(database, "positions");
    const ordersRef = ref(database, "orders");
    const usersRef = ref(database, "users");

    get(usersRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const usersData = Object.entries(snapshot.val()).map(
            ([key, value]) => {
              const { color, email, surname, username } = value;
              return { key, color, email, surname, username };
            },
          );
          setUsers(usersData);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error.message);
      });

    // eslint-disable-next-line no-undef
    get(positionsRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const positionsData = Object.entries(snapshot.val()).map(
            ([key, value]) => ({ key, ...value }),
          );
          setPositions(positionsData);
        }
      })
      .catch((error) => {
        console.error("Error fetching positions:", error.message);
      });

    // eslint-disable-next-line no-undef
    get(ordersRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const ordersData = Object.entries(snapshot.val()).map(
            ([key, value]) => ({ key, ...value }),
          );
          const sortedOrders = ordersData.sort(
            (a, b) => a.orderNumber - b.orderNumber,
          );
          setOrders(sortedOrders);
        }
      })
      .catch((error) => {
        console.error("Error fetching orders:", error.message);
      });
  }, [database]);

  // New functions to handle orders
  const handleEditOrderInline = (key, newTableNumber, newPosition) => {
    updateOrder(key, { tableNumber: newTableNumber, position: newPosition });
  };

  const updateOrder = async (key, newOrderData) => {
    try {
      const orderRef = ref(database, `orders/${key}`);

      // Update the order in the orders table
      await update(orderRef, newOrderData);

      // Fetch orders again after updating the order
      const updatedOrdersSnapshot = await get(orderRef);
      if (updatedOrdersSnapshot.exists()) {
        const updatedOrdersData = Object.entries(
          updatedOrdersSnapshot.val(),
        ).map(([key, value]) => ({
          key,
          ...value,
        }));
        setOrders((prevOrders) => {
          const updatedOrders = prevOrders.map((order) =>
            order.key === key ? { ...order, ...newOrderData } : order,
          );
          return updatedOrders;
        });
      }
    } catch (error) {
      console.error("Error updating order:", error.message);
    }
  };

  const handleBatchOrderSubmit = async (e) => {
    e.preventDefault();

    if (!batchStart || !batchEnd || !batchPosition) {
      alert("Please fill in all fields for batch addition.");
      return;
    }

    const start = parseInt(batchStart);
    const end = parseInt(batchEnd);

    if (start > end) {
      alert("Start table number cannot be greater than end table number.");
      return;
    }

    for (let tableNumber = start; tableNumber <= end; tableNumber++) {
      await addOrderForTable(tableNumber, batchPosition);
    }

    setBatchStart("");
    setBatchEnd("");
    setBatchPosition("");
  };

  const addOrderForTable = async (tableNumber, position) => {
    try {
      const tableNumberHash = md5(tableNumber);
      const orderRef = ref(database, `orders/${tableNumberHash}`);
      const existingOrderSnapshot = await get(orderRef);

      if (!existingOrderSnapshot.exists()) {
        await set(orderRef, {
          tableNumber: parseInt(tableNumber),
          tableNumberHash: tableNumberHash,
          position: position,
          called: true,
        });

        const updatedOrderSnapshot = await get(orderRef);
        if (updatedOrderSnapshot.exists()) {
          const updatedOrderData = {
            key: updatedOrderSnapshot.key,
            ...updatedOrderSnapshot.val(),
          };

          setOrders((prevOrders) => [...prevOrders, updatedOrderData]);
        }
      }
    } catch (error) {
      console.error("Error adding order for table number", tableNumber, error);
    }
  };

  // function to handle saving the edited order
  const handleSaveEditOrder = async (key, newOrderData) => {
    try {
      const orderRef = ref(database, `orders/${key}`);
      await update(orderRef, newOrderData);

      // Fetch orders again after updating the order
      const updatedOrdersSnapshot = await get(orderRef);
      if (updatedOrdersSnapshot.exists()) {
        const updatedOrdersData = Object.entries(
          updatedOrdersSnapshot.val(),
        ).map(([key, value]) => ({
          key,
          ...value,
        }));
        setOrders((prevOrders) => {
          const updatedOrders = prevOrders.map((order) =>
            order.key === key ? { ...order, ...newOrderData } : order,
          );
          return updatedOrders;
        });
      }

      setEditOrderKey(null); // Reset the order key being edited
    } catch (error) {
      console.error("Error updating order:", error.message);
    }
  };
  
  const confirmDeleteOrder = (key) => {
    if (window.confirm("Are you sure you want to delete this Table?")) {
      handleDeleteOrder(key); // Call the delete function if confirmed
    }
  };
  const handleDeleteOrder = async (key) => {
    
    try {
      const orderRef = ref(database, `orders/${key}`);
      await remove(orderRef);

      // Update state by removing the deleted order
      setOrders((prevOrders) =>
        prevOrders.filter((order) => order.key !== key),
      );
      
    } catch (error) {
      console.error("Error deleting order:", error.message);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!tableNumber || !position) {
      alert("Please fill in all fields");
      return;
    }

    try {
      const tableNumberHash = md5(tableNumber);
      const orderRef = ref(database, `orders/${tableNumberHash}`);
      const existingOrderSnapshot = await get(orderRef);

      if (existingOrderSnapshot.exists()) {
        setTableExistsMessage("Table number already exists. Please choose a different table number.");
        // Clear the message after 5 seconds
        setTimeout(() => {
          setTableExistsMessage("");
        }, 5000);
        return;
      }

      const positionData = positions.find((pos) => pos.position === position);

      await set(orderRef, {
        tableNumber: tableNumber,
        tableNumberHash: tableNumberHash,
        position: positionData.position,
        called: true,
      });

      const updatedOrderSnapshot = await get(orderRef);
      if (updatedOrderSnapshot.exists()) {
        const updatedOrderData = {
          key: updatedOrderSnapshot.key,
          ...updatedOrderSnapshot.val(),
        };

        setOrders((prevOrders) => [...prevOrders, updatedOrderData]);
      }

      onSubmit(tableNumber, positionData.position);
      setTableNumber("");
      setPosition("");
      setTableExistsMessage(""); // Reset the table exists message after successful submission
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };

  const handleSaveNewPosition = async () => {
    try {
      const positionsRef = ref(database, "positions");
      const newPositionRef = push(positionsRef);

      await set(newPositionRef, {
        position: newPosition,
        userID: newUserID,
        positionsColor: selectedPositionColor,
        "order-positions": orderPositionsValue 
      });

      // Fetch positions again after saving the new position
      const updatedPositionsSnapshot = await get(positionsRef);
      if (updatedPositionsSnapshot.exists()) {
        const updatedPositionsData = Object.entries(
          updatedPositionsSnapshot.val(),
        ).map(([key, value]) => ({
          key,
          ...value,
        }));
        setPositions(updatedPositionsData);
      }

      setNewPosition("");
      setSelectedPositionColor("#000000"); // Reset color picker to default after submission
      setOrderPositionsValue("");
    } catch (error) {
      console.error("Error saving new position:", error.message);
    }
  };

  // Update the handleEditPosition function
  const updatePosition = async (key, newPosition, newPositionColor) => {
    try {
      const positionRef = ref(database, `positions/${key}`);
      const orderRef = ref(database, "orders");

      // Fetch orders to get the list of orders
      const ordersSnapshot = await get(orderRef);
      const ordersData = ordersSnapshot.exists()
        ? Object.entries(ordersSnapshot.val()).map(([key, value]) => ({
            key,
            ...value,
          }))
        : [];

      // Fetch the current position data
      const currentPositionData = positions.find((pos) => pos.key === key);

      // Update the position in the positions table
      await update(positionRef, {
        position: newPosition,
        userID: newUserID,
        positionsColor: newPositionColor,
      });

      // Update the position in the orders table for each order
      for (const order of ordersData) {
        if (order.position === currentPositionData.position) {
          const orderKey = order.key;
          const orderUpdateRef = ref(database, `orders/${orderKey}`);
          await update(orderUpdateRef, { position: newPosition });
        }
      }

      // Fetch positions again after updating the position
      const updatedPositionsSnapshot = await get(positionRef);
      if (updatedPositionsSnapshot.exists()) {
        const updatedPositionsData = Object.entries(
          updatedPositionsSnapshot.val(),
        ).map(([key, value]) => ({
          key,
          ...value,
        }));
        setPositions((prevPositions) => {
          const updatedPositions = prevPositions.map((pos) =>
            pos.key === key
              ? {
                  ...pos,
                  position: newPosition,
                  userID: newUserID,
                  positionsColor: newPositionColor,
                }
              : pos,
          );
          return updatedPositions;
        });
      }

      // Fetch orders again after updating the positions in the orders table
      const updatedOrdersSnapshot = await get(orderRef);
      if (updatedOrdersSnapshot.exists()) {
        const updatedOrdersData = Object.entries(
          updatedOrdersSnapshot.val(),
        ).map(([key, value]) => ({
          key,
          ...value,
        }));
        setOrders(updatedOrdersData);
      }
    } catch (error) {
      console.error("Error updating position:", error.message);
    }
  };

  const handleEditOrderPositions = async (key, newOrderPositions) => {
  try {
    const positionRef = ref(database, `positions/${key}`);
    await update(positionRef, { 'order-positions': newOrderPositions });

    // Fetch positions again after updating the order positions
    const updatedPositionsSnapshot = await get(positionRef);
    if (updatedPositionsSnapshot.exists()) {
      const updatedPositionData = {
        key,
        ...updatedPositionsSnapshot.val(),
      };
      
      setPositions(prevPositions => {
        const updatedPositions = prevPositions.map(position => 
          position.key === key ? { ...position, ...updatedPositionData } : position
        );
        return updatedPositions;
      });
    }
  } catch (error) {
    console.error("Error updating order positions:", error.message);
  }
};
  
  const handleEditPositionInline = (key, newName, currentColor) => {
    updatePosition(key, newName, currentColor);
  };

  const handleEditColorInline = (key, currentPosition, newColor) => {
    updatePosition(key, currentPosition, newColor);
  };

  const confirmDeletePosition = (key) => {
    if (window.confirm("Are you sure you want to delete this Tables Goup?")) {
      handleDeletePosition(key); // Call the delete function if confirmed
    }
  };
  const handleDeletePosition = async (key) => {
    try {
      const positionRef = ref(database, `positions/${key}`);
      const orderRef = ref(database, "orders");

      // Fetch orders to get the list of orders
      const ordersSnapshot = await get(orderRef);
      const ordersData = ordersSnapshot.exists()
        ? Object.entries(ordersSnapshot.val()).map(([key, value]) => ({
            key,
            ...value,
          }))
        : [];

      // Delete the position from the positions table
      await remove(positionRef);

      // Update the orders that have the deleted position
      for (const order of ordersData) {
        if (
          order.position === positions.find((pos) => pos.key === key).position
        ) {
          const orderKey = order.key;
          const orderUpdateRef = ref(database, `orders/${orderKey}`);
          await update(orderUpdateRef, { position: null });
        }
      }

      // Fetch orders again after updating the positions in the orders table
      const updatedOrdersSnapshot = await get(orderRef);
      if (updatedOrdersSnapshot.exists()) {
        const updatedOrdersData = Object.entries(
          updatedOrdersSnapshot.val(),
        ).map(([key, value]) => ({
          key,
          ...value,
        }));
        setOrders(updatedOrdersData);
      }

      // Update state by removing the deleted position
      setPositions((prevPositions) =>
        prevPositions.filter((pos) => pos.key !== key),
      );
    } catch (error) {
      console.error("Error deleting position:", error.message);
    }
  };

  const handleEditUserInline = (
    key,
    newUsername,
    newSurname,
    newEmail,
    newColor,
  ) => {
    updateUser(key, newUsername, newSurname, newEmail, newColor);
  };

  const updateUser = async (
    key,
    newUsername,
    newSurname,
    newEmail,
    newColor,
  ) => {
    try {
      const userRef = ref(database, `users/${key}`);
      const updatedUser = {
        username: newUsername,
        surname: newSurname,
        email: newEmail,
        color: newColor,
      };

      // Update the user in the users table
      await update(userRef, updatedUser);

      // Fetch users again after updating the user
      const updatedUsersSnapshot = await get(userRef);
      if (updatedUsersSnapshot.exists()) {
        const updatedUsersData = Object.entries(updatedUsersSnapshot.val()).map(
          ([key, value]) => ({
            key,
            ...value,
          }),
        );
        setUsers((prevUsers) => {
          const updatedUsers = prevUsers.map((user) =>
            user.key === key ? { ...user, ...updatedUser } : user,
          );
          return updatedUsers;
        });
      }
    } catch (error) {
      console.error("Error updating user:", error.message);
    }
  };

  const confirmDeleteUser = (key) => {
    if (window.confirm("Are you sure you want to delete this User?")) {
      handleDeleteUser(key); // Call the delete function if confirmed
    }
  };
  const handleDeleteUser = async (key) => {
    try {
      const userRef = ref(database, `users/${key}`);

      // Delete the user from the users table
      await remove(userRef);

      // Update the state immediately after deletion
      setUsers((prevUsers) => prevUsers.filter((user) => user.key !== key));
    } catch (error) {
      console.error("Error deleting user:", error.message);
    }
  };

  return (
    <>
    <Header />
    <div className="min-h-screen flex flex-col dark:bg-gray-800  bg-primaryLight-300 ">
      <div className="block md:flex mx-auto md:gap-4 gap-2 flex-1 w-full px-2 md:px-6 mt-12 md:mt-14">
        {/* Left Content */}
        <div className="flex-1 bg-primaryLight-200 mb-4 p-5 rounded-large shadow-md dark:bg-gray-400">
          <h1 className="text-3xl pb-5">Settings</h1>
          {tableExistsMessage && (
            <span className="text-red-500 mb-4">{tableExistsMessage}</span>
          )}
          <form onSubmit={handleFormSubmit}>
          <h3 className="text-xl">Add New Table</h3>
            <label>
              Table Number: 
              <input
                type="text"
                value={tableNumber}
                onChange={(e) => setTableNumber(e.target.value)}
                className='mx-2 w-20 bg-gray-100 appearance-none border-2 border-gray-200 rounded py-0.5  text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"'
              />
            </label>
              <select
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                className="appearance-none w-36 bg-white border border-gray-400 hover:border-gray-500 px-2 py-1 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="">Select Group</option>
                {positions.map((pos) => (
                  <option key={pos.key} value={pos.position}>
                    {pos.position}
                  </option>
                ))}
              </select>
            <button
              className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1 px-3 border border-gray-400 rounded shadow"
              type="submit"
            >
              Create
            </button>
          </form>
          <form onSubmit={handleBatchOrderSubmit} className="mt-4">
            <input
              type="number"
              value={batchStart}
              onChange={(e) => setBatchStart(e.target.value)}
              placeholder="From"
              className="w-24 border border-gray-400 rounded py-1 px-2"
            />
            <input
              type="number"
              value={batchEnd}
              onChange={(e) => setBatchEnd(e.target.value)}
              placeholder="Until"
              className="w-24 border border-gray-400 rounded py-1 px-2"
            />
            <select
              value={batchPosition}
              onChange={(e) => setBatchPosition(e.target.value)}
              className="border border-gray-400 rounded px-2 py-1"
              placeholder="Select Group"
            >
              <option value="">Select Position</option>
              {positions.map((pos) => (
                <option key={pos.key} value={pos.position}>
                  {pos.position}
                </option>
              ))}
            </select>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Create
          </button>
        </form>
          <div className="py-5 ">
          <h3 className="text-xl">Table Groups</h3>
              <input
                type="text" // Input field for order-positions
                placeholder="Order Positions"
                value={orderPositionsValue} // Make sure to bind this to the state or a variable
                onChange={(e) => setOrderPositionsValue(e.target.value)} // Define a function to handle changes
                className="bg-gray-100 appearance-none border-2 border-gray-200 rounded py-2 px- text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
              />
            <input
              type="text"
              placeholder="Add New Table Group"
              value={newPosition}
              onChange={(e) => setNewPosition(e.target.value)}
              className="bg-gray-100 appearance-none border-2 border-gray-200 rounded py- px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            />
            <input
              label="Position Color"
              type="color"
              value={selectedPositionColor}
              onChange={(e) => setSelectedPositionColor(e.target.value)}
            />
            <button
              className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-1 px-3 border border-gray-400 rounded shadow"
              onClick={handleSaveNewPosition}
            >
              Create
            </button>
         
            <table>
              <thead>
                <tr>
                  <th>Order Positions</th>
                  <th>Position</th>
                  <th>Color</th>
                </tr>
              </thead>
              <tbody>
                {positions.map((pos) => (
                  <tr key={pos.key}>
                    <td>
                      <input
                        type="text"
                        value={pos['order-positions']} // Bind to the order-positions field
                        onChange={(e) =>
                          handleEditOrderPositions(pos.key, e.target.value)
                        }
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      />
                    </td>
                    <td>
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500  "
                        type="text"
                        value={pos.position}
                        onChange={(e) =>
                          handleEditPositionInline(
                            pos.key,
                            e.target.value,
                            pos.positionsColor,
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="color"
                        value={pos.positionsColor}
                        onChange={(e) =>
                          handleEditColorInline(
                            pos.key,
                            pos.position,
                            e.target.value,
                          )
                        }
                      />
                    </td>
                    <td>
                      <button
                        className="bg-red-500 hover:bg-gray-800 text-primaryLight-300 text-xs md:text-base font-semibold py-1 px-3  rounded shadow"
                        onClick={() => confirmDeletePosition(pos.key)}
                      >
                        Delete Group
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div>
            <h2>Registered Users</h2>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Surname</th>
                  <th>Email</th>
                  <th>Color</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.key}>
                    <td>
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500  "
                        type="text"
                        value={user.username}
                        onChange={(e) =>
                          handleEditUserInline(
                            user.key,
                            e.target.value,
                            user.surname,
                            user.email,
                            user.color,
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500  "
                        type="text"
                        value={user.surname}
                        onChange={(e) =>
                          handleEditUserInline(
                            user.key,
                            user.username,
                            e.target.value,
                            user.email,
                            user.color,
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500  "
                        type="text"
                        value={user.email}
                        onChange={(e) =>
                          handleEditUserInline(
                            user.key,
                            user.username,
                            user.surname,
                            e.target.value,
                            user.color,
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        className='block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name'
                        type="color"
                        value={user.color}
                        onChange={(e) =>
                          handleEditUserInline(
                            user.key,
                            user.username,
                            user.surname,
                            user.email,
                            e.target.value,
                          )
                        }
                      />
                    </td>
                    <td>
                      <button
                        className="bg-red-500 hover:bg-gray-800 text-primaryLight-300 text-xs md:text-base font-semibold py-1 px-3  rounded shadow"
                        onClick={() => confirmDeleteUser(user.key)}
                      >
                        Delete User
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Right Content */}
        <div className="rounded-large shadow-md bg-primaryLight-200 dark:bg-gray-400 justify-center block pb-2 w-auto md:w-1/3 p-5 mb-4">
          <h2 className="text-3xl pb-4 ">Tables</h2>
          <table>
            <thead>
              <tr>
                <th className="text-sm w-14 leading-none font-medium">Number</th>
                <th className="text-sm  leading-none font-medium ">Position</th>
              </tr>
            </thead>
            <tbody>
              {orders
                .sort((a, b) => a.tableNumber - b.tableNumber)
                .map((order) => (
                  <tr key={order.key}>
                    <td>
                      <input
                        className="bg-white border border-gray-400 rounded hover:border-gray-500 appearance-none bg-transparent border-none w-12  text-gray-700 mr-0 py-0.5 px-2 leading-tight focus:outline-none"
                        type="text"
                        value={
                          editOrderKey === order.key
                            ? order.tableNumber
                            : order.tableNumber
                        }
                        onChange={(e) =>
                          handleEditOrderInline(
                            order.key,
                            e.target.value,
                            order.position,
                          )
                        }
                      />
                    </td>
                    <td>
                      <label>
                        <select
                          className="w-32 bg-white border border-gray-400 hover:border-gray-500 px-2 py-0.5 rounded shadow leading-tight focus:outline-none focus:shadow-outline mr-1"
                          value={
                            editOrderKey === order.key
                              ? order.position
                              : order.position
                          }
                          onChange={(e) =>
                            handleEditOrderInline(
                              order.key,
                              order.tableNumber,
                              e.target.value,
                            )
                          }
                        >
                          <option value="">Select position</option>
                          {positions.map((pos) => (
                            <option key={pos.key} value={pos.position}>
                              {pos.position}
                            </option>
                          ))}
                        </select>
                      </label>
                    </td>
                    <td>
                      <button
                        className="bg-red-500 hover:bg-gray-800 text-primaryLight-300 text-xs md:text-base font-semibold py-0.5 mr-1 px-3  rounded shadow"
                        onClick={() => confirmDeleteOrder(order.key)}
                      >
                        Delete
                      </button>
                    </td>
                    <td>
                    <a
                        href={`/orders/${order.tableNumberHash}`}
                        className="bg-blue-500 hover:bg-blue-700 text-white md:py-0.5 md:px-3 px-1 rounded shadow md:text-base text-font0 py-2.5"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Preview
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="md:w-6 md:h-6 h-4 w-4 inline ml-1 md:-mr-1 md:pb-1">
                          <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                          <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z" clip-rule="evenodd" />
                        </svg>
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </>
  );
};

export default Settings;
