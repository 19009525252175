import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth'; // Import from 'firebase/auth' for authentication
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, set, get } from 'firebase/database'; // Import from 'firebase/database' for Realtime Database
import { getPositions, auth } from '../../firebaseConfig'; // Adjust the import based on your actual 
import logo from '../../assets/pome-logo-white.svg';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [positions, setPositions] = useState([]); 
  const [selectedPositions, setSelectedPositions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        const positionsData = await getPositions();
        setPositions(positionsData);
      } catch (error) {
        console.error('Error fetching positions:', error.message);
      }
    };

    fetchPositions();
  }, []);

  const handleLogin = async () => {
    try {
      // Firebase authentication
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      // Realtime Database operations
      const database = getDatabase();
  
      // Assuming `getPositions` returns an array of position objects
      const positionsData = await getPositions();
      
      // Find the selected position names based on the selected keys
      const selectedPositionNames = selectedPositions.map((selectedKey) => {
        const selectedPosition = positionsData.find((position) => position.key === selectedKey);
        return selectedPosition ? selectedPosition.position : null;
      });
  
      const userRef = ref(database, `users/${user.uid}`);
  
      // Fetch the existing user data from the database
      const snapshot = await get(userRef);
  
      // Merge the existing data with the new data
      const updatedUserData = {
        ...snapshot.val(), // Existing data
        selectedPositions: selectedPositionNames.filter(Boolean), // Filter out null values
      };
  
      // Update the user data in the database
      set(userRef, updatedUserData);
  
      console.log('User logged in successfully!');
      navigate('/');
      
    } catch (error) {
      console.error('Error logging in:', error.message);
  
      // Display a user-friendly error message
      if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        alert('Invalid email or password. Please check your credentials and try again.');
      } else {
        alert('An error occurred while logging in. Please try again later.');
      }
    }
  };


  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center">
       <div className="sm:mx-auto sm:w-full sm:max-w-sm bg-gray-800 p-6 rounded-lg  ">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
            <img src={logo} alt="Logo" className=" mx-auto h-28" />  
            
            <h2 className="mt-5 text-center text-2xl  tracking-tight text-gray-100">
              Sign in 
            </h2>
          </div>

          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-1" action="#" method="POST">
              <div>
                <label htmlFor="email" className="block text-sm  leading-6 text-gray-100">
                  Email address
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-700">
                  <svg className='w-6 h-6 fill-gray-600' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  >
                    <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                    <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                  </svg>

                  </div>
                  <input
                    placeholder="Your Email:"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="pl-10 block w-full border-0 py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6 rounded-md"
                  />
                </div>
              </div>

              <div>
                <div className="flex mt-2 items-center justify-between">
                  <label htmlFor="password" className="block text-sm  text-gray-100">
                    Password
                  </label>
                </div>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600 fill-gray-600" viewBox="0 0 20 20"	>
                      <path fill-rule="evenodd"	d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <input
                    placeholder="Password:"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="pl-10 block w-full border-0 py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6 rounded-md"
                  />
                </div>
              </div>

              <div className="mt-2 space-y-2">
                {/* Checkboxes for positions */}
                {positions.map((position) => (
                  <div key={position.key} className=" items-center inline-block  pr-3">
                    <label className="flex items-center cursor-pointer">
                      <input
                        className="rounded cursor-pointer"
                        type="checkbox"
                        checked={selectedPositions.includes(position.key)}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setSelectedPositions((prevSelectedPositions) =>
                            isChecked
                              ? [...prevSelectedPositions, position.key]
                              : prevSelectedPositions.filter((selected) => selected !== position.key)
                          );
                        }}
                      />
                      <span className="ml-2 text-gray-100">{position.position}</span>
                    </label>
                  </div>
                ))}
              </div>
            </form>
            <button onClick={handleLogin}
                  type="submit"
                  className="mt-5 flex w-full justify-center rounded-md bg-blue-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
                
            <p className="mt-10 text-center text-sm text-gray-500">
              Don't have an account?{' '}
              <a href="/register" className="font-semibold leading-6 text-blue-700 hover:text-indigo-500">
              Register
              </a>
            </p>
          </div>
        </div>

      </div>
      
  );
};

export default Login;