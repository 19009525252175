// WaitersPage.js
import React, { useEffect, useState } from "react";
import { onValue, ref, update, getDatabase, get,} from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { database } from "../firebaseConfig";
import DeleteArchivedCallsButton from "../components/WaitersPage/DeleteArchivedCallsButton";
import Header from "../../src/components/Header/Header";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon, CheckIcon } from '@heroicons/react/solid';
import SwipeToDelete from 'react-swipe-to-delete-ios';
import SwipeableViews from "react-swipeable-views";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

 // CalledTableTab
 const CalledTableTab = ({
  orders,
  selectedPositions,
  handleSetCalledFalse,
  positions,
}) => {
  const [prevCallsLength, setPrevCallsLength] = useState(0);
  const [sortBy, setSortBy] = useState("asc"); // Default sorting order is ascending

  const filteredOrders = orders.filter((order) => order.called && order.callTimes);

  

  const allCalls = filteredOrders
    .filter((order) => selectedPositions.includes(order.position))
    .flatMap((order) =>
      order.callTimes.map((callTime) => ({ ...order, callTime }))
    )
    .sort((a, b) => {
      if (sortBy === "asc") {
        return new Date(a.callTime) - new Date(b.callTime);
      } else {
        return new Date(b.callTime) - new Date(a.callTime);
      }
    });

  const toggleSortOrder = () => {
    setSortBy(sortBy === "asc" ? "desc" : "asc");
  };

  return (
    <>
      <button onClick={toggleSortOrder}>
            {sortBy === "asc" ? <ChevronDownIcon className="h-5 w-5 dark:text-white" /> : <ChevronUpIcon className="h-5 w-5 dark:text-white" />}
      </button>
      {allCalls.map((call, index) => (
         <SwipeToDelete 
          className=" mb-1.5 rounded-xl"
          key={`${call.tableNumber}_${index}`}
          onDelete={() => handleSetCalledFalse(call.key)}
          backgroundColor="red" // Specify the background color for swipe action
          swipeThreshold={40} // Adjust swipe threshold as needed
          deleteThreshold={50}
          height={80}
          transitionDuration={250} // default
          deleteWidth={75} // default
          deleteText='Served' // default
          howDeleteAction={true} //default
          deleteColor="#EF4444" // default
          disabled={false} // default
        >
        <div className="items-center justify-center cursor-pointer mb-1.5  px-1.5 py-1.5"
          
          key={`${call.tableNumber}_${index}`}
          style={{
            background:
              positions.find((pos) => pos.position === call.position)
                ?.positionsColor || "transparent ",
          }}
        >
          {/* Rendering TableList here */}
          <div className="inline-flex items-center ">
            <div className="md:text-3xl text-2xl font-semibold text-gray-700 mr-2 bg-gray-100 bg-opacity-70 px-2 rounded-lg md:min-w-20 md:min-h-10 min-w-14 min-h-9 flex justify-center items-center">
              {call.tableNumber}
            </div>
            <div className="text-2xl text-gray-700 "> {call.position}</div>
          </div>
          <div className="float-right flex items-center justify-center  font-semibold text-xl md:min-w-8 md:min-h-8 min-w-8 min-h-8 leading-12 px-2 rounded-full z-10 transition-transform bg-red-500 text-white top-0 right-0 ">
            {call.callTimes.length || 0}
          </div>
          <div className="flex  justify-between pl-1 items-end">
            <div className="text-sm text-gray-700">
              {call.callTime && (
                <span>
                  {new Intl.DateTimeFormat("en-US", {
                    weekday: "long",
                  }).format(new Date(call.callTime))}
                  {new Date(call.callTime).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              )}
            </div>
            <button
              type="button"
              className="transition duration-300 linear hover:ease-in inline-flex items-center text-sm font-semibold text-blue-700 dark:text-gray-800    hover:text-green-800 dark:hover:text-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => handleSetCalledFalse(call.key)}
            >
              Served
              <CheckIcon
                className="ml-1 p-1  h-8 w-8 bg-blue-700 dark:bg-gray-800 hover:bg-green-800 dark:hover:bg-green-800 rounded-full text-gray-100"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
        </SwipeToDelete>
      ))}
    </>
  );
};

const ArchivedOrders = ({ archivedOrders }) => {
  const [ordersWithUserInfo, setOrdersWithUserInfo] = useState([]);

  const fetchUserInfo = async (userId) => {
    try {
      const userRef = ref(database, `users/${userId}`);
      const userSnapshot = await get(userRef);
      return userSnapshot.val();
    } catch (error) {
      console.error("Error fetching user information:", error);
      return null;
    }
  };

  const fetchUserInformationForOrder = async (order) => {
    if (!order || !order.archivedCallsTime || !Array.isArray(order.archivedCallsTime)) {
      return [];
    }

    const callsWithUserInfo = await Promise.all(
      order.archivedCallsTime.map(async (archivedCall) => {
        const user = await fetchUserInfo(archivedCall.userId);
        const callInfo = {
          archivedTime: new Date(archivedCall.archivedTime).getTime(),
          callTime: new Date(archivedCall.callTime).toLocaleTimeString(),
          userId: archivedCall.userId,
          user,
          tableNumber: order.tableNumber,
        };

        return callInfo;
      })
    );

    return callsWithUserInfo;
  };

  useEffect(() => {
    const fetchData = async () => {
      const updatedOrders = await Promise.all(
        archivedOrders.map(async (order) => {
          const callsWithUserInfo = await fetchUserInformationForOrder(order);
          return {
            tableNumber: order.tableNumber,
            calls: callsWithUserInfo,
          };
        })
      );

      const allCalls = updatedOrders.map((order) => order.calls).flat();
      const sortedCalls = allCalls.sort((a, b) => b.archivedTime - a.archivedTime);

      setOrdersWithUserInfo(sortedCalls);
    };

    fetchData();
  }, [archivedOrders]);

  return (
    <>
      {ordersWithUserInfo.map((call, index, callsArray) => {
        const showHourSeparator =
          index === 0 || new Date(callsArray[index - 1].archivedTime).getHours() !== new Date(call.archivedTime).getHours();
  
        return (
          <div key={index}>
            {showHourSeparator && (
              <div className="border-t border-gray-400  my-2 mx-4" ></div>
            )}
            <div className="bg-indigo-300 m-1 rounded-lg text-sm mx-2.5">
              <div className="inline-flex items-center py-1 px-1">
                <div className="text-2xl font-semibold text-gray-700 mr-2 bg-gray-100 bg-opacity-70 px-2 rounded-lg min-w-18 min-h-10  flex justify-center items-center">
                  {call.tableNumber}
                </div>
                <div className="text-gray-700">Served By: </div>
                <div className="text-lg font-semibold text-gray-700 ml-2">
                  {call.user.username} {call.user.surname}
                </div>
              </div>
              <div className="flex items-center justify-between pb-1 px-4">
                {call.user && (
                  <div className="text-xs"> Called at: {call.callTime}</div>
                )}
                <div className="text-xs">Served at: {new Date(call.archivedTime).toLocaleTimeString()}</div>
              </div>
            </div>
          </div>
        );
      })}
      <DeleteArchivedCallsButton />
    </>
  );
};

// Waiter component
const Waiter = () => {
  const [localOrders, setLocalOrders] = useState([]);
  const [archivedOrders, setArchivedOrders] = useState([]);
  const [allTableNumbers, setAllTableNumbers] = useState([]);
  const [activeTab, setActiveTab] = useState("call"); // 'call' or 'archived'
  const setCounter = useState(0); // Counter state
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [positions, setPositions] = useState([]); // Updated state for positions
  const [showSelectedPositions, setShowSelectedPositions] = useState([]);
  const [allPositions, setAllPositions] = useState(false);
  const [userUnsubscribe, setUserUnsubscribe] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  
  const handleTogglePosition = async (position) => {
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const userRef = ref(database, `users/${userId}`);

    try {
      const snapshot = await get(userRef);
      const currentSelectedPositions = snapshot.val()?.selectedPositions || [];

      const updatedPositions = currentSelectedPositions.includes(position)
        ? currentSelectedPositions.filter((pos) => pos !== position)
        : [...currentSelectedPositions, position];

      // Update the state and the database with the new selected positions
      setSelectedPositions(updatedPositions);
      setShowSelectedPositions(updatedPositions);
      await update(userRef, { selectedPositions: updatedPositions });
    } catch (error) {
      console.error("Error updating position:", error);
    }
  };

  // Add a function to handle toggling "All Positions" checkbox
  const handleToggleAllPositions = async () => {
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const userRef = ref(database, `users/${userId}`);

    try {
      const snapshot = await get(userRef);
      const currentSelectedPositions = snapshot.val()?.selectedPositions || [];

      // Toggle the "All Positions" checkbox
      const updatedPositions = allPositions
        ? []
        : positions.map((pos) => pos.position);

      // Update the state and the database with the new selected positions
      setSelectedPositions(updatedPositions);
      setShowSelectedPositions(updatedPositions);
      await update(userRef, { selectedPositions: updatedPositions });
      setAllPositions((prevAllPositions) => !prevAllPositions);
    } catch (error) {
      console.error("Error updating positions:", error);
    }
  };

  // TablesByPosition
  const TablesByPosition = ({
    showPositions,
    allPositions,
    handleTogglePosition,
    handleToggleAllPositions,
  }) => {
    const tablesByPosition = localOrders.reduce((acc, order) => {
      if (!acc[order.position]) {
        acc[order.position] = [];
      }
      acc[order.position].push(order);
      return acc;
    }, {});
  
    // Check if all positions are selected
    const areAllPositionsSelected =
      positions.length > 0 && showPositions.length === positions.length;
  
    // Check if all positions are unchecked
    const areAllPositionsUnchecked =
      positions.length > 0 && showPositions.length === 0;

      
     // Fetch order-positions from the database and sort positions based on them
const sortedPositions = positions.slice().sort((a, b) => {
  return a["order-positions"] - b["order-positions"];
});

return (
  <div>
    <div className="flex md:space-x-2 space-x-1 rounded-t-large pl-1 bg-stone-500 dark:bg-gray-600 justify-start md:justify-center items-center py-2 h-11 md:h-my55 overflow-x-auto whitespace-nowrap no-scrollbar">
      <label
        className={`text-gray-100 cursor-pointer px-2.5 py-2 md:px-4 md:py-2 text-xs md:text-base rounded-full ${areAllPositionsSelected ? 'text-stone-800 bg-primaryLight-100 dark:text-stone-100 dark:bg-gray-800' : ''}`}
        onClick={() => handleToggleAllPositions()}
      >
        All
      </label>

      {sortedPositions.map((pos) => (
        <label
          className={`text-gray-100 cursor-pointer px-2 py-2 md:px-4 md:py-2 text-xs md:text-base rounded-full ${showPositions.includes(pos.position) ? 'text-stone-800 bg-primaryLight-300 dark:text-stone-100 dark:bg-gray-700' : ''}`}
          key={pos.key}
          onClick={() => handleTogglePosition(pos.position)}
        >  
          {pos.position}
        </label>
      ))}
    </div>
    {areAllPositionsUnchecked && (
      <p className="dark:text-gray-300 text-gray-600 text-3xl text-center align-middle pt-16">
        Please select your tables group.
      </p>
    )}
    {sortedPositions.map((pos) => (
      Object.entries(tablesByPosition).map(([position, orders]) => (
        // Check if the position is selected before rendering
        showPositions.includes(position) && position === pos.position && (
          <div className="relative px-1 md:px-4" key={position}>
            <h2 className="text-stone-800 dark:text-stone-200 text-xl px-2 py-1.5">
              {position}:
            </h2>
            {orders
              .sort((a, b) => a.tableNumber - b.tableNumber)
              .map((order) => (
                <div
                  className="inline-block m-0.5 md:m-1.5 rounded-md"
                  key={order.tableNumber}
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      background:
                        positions.find((pos) => pos.position === position)
                          ?.positionsColor || "transparent ",
                    }}
                    className={`rounded-md  ${
                      calledTables.includes(order.tableNumber) ? "called " : ""
                    }`}
                  >
                    {/* Render the dropdown if there are call times */}
                    <Menu>
                      <Menu.Button className="items-center gap-x-1.5 text-gray-700 focus:outline-none h-11 w-11 md:w-14 md:h-14  justify-center">
                        {order.callTimes && (
                          <div className=" flex flex-wrap justify-center items-center absolute box-border font-bold md:text-xs text-2xs leading-none h-4 w-4 md:h-5 md:w-5 rounded-full z-20  bg-red-500 text-white  md:translate-x-2 md:-translate-y-3 translate-x-1 -translate-y-2.5 right-0">
                            {order.callTimes.length}
                          </div>
                        )}
                        <div className="text-xl md:text-2xl font-semibold leading-none md:leading-tight">
                          {order.tableNumber}
                        </div>
                        <div className="text-xs md:text-sm leading-none md:leading-tight">Table</div>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute md:bottom-14 bottom-12 -left-5 z-10 w-max origin-top-left rounded-md bg-white dark:bg-red-500   ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            {order.callTimes && order.callTimes.length > 0
                              ? order.callTimes.map((callTime, index) => (
                                  <Menu.Item key={index}>
                                    {({ active }) => (
                                      <p
                                        className={classNames(
                                          active
                                            ? "dark:bg-red-400 dark:text-gray-200 bg-gray-100 text-gray-900"
                                            : "text-gray-700 dark:text-stone-200",
                                          "block px-3 py-1 text-xs",
                                        )}
                                      >
                                        {new Date(callTime).toLocaleString(
                                          "en-US",
                                          {
                                            weekday: "long",
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: false,
                                          },
                                        )}
                                      </p>
                                    )}
                                  </Menu.Item>
                                ))
                              : null}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              ))}
          </div>
        )
      ))
    ))}
  </div>
);
};

    

  useEffect(() => {
    const database = getDatabase();
    const ordersRef = ref(database, "orders");
    const auth = getAuth();

    const userUnsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userId = user.uid;
        const usersRef = ref(database, "users");

        const userUnsubscribe = onValue(usersRef, (snapshot) => {
          if (snapshot.exists()) {
            const usersData = snapshot.val();
            const userData = usersData[userId];

            if (
              userData &&
              userData.selectedPositions &&
              userData.selectedPositions.length > 0
            ) {
              // Update the state with the selected positions
              setShowSelectedPositions(userData.selectedPositions);
              setSelectedPositions(userData.selectedPositions);
            } else {
              // If no selected positions exist, set the state accordingly
              setShowSelectedPositions([]);
              setSelectedPositions([]);
            }
          } else {
            // If the user does not exist, set the state accordingly
            setShowSelectedPositions([]);
            setSelectedPositions([]);
          }
        });

        // Save the unsubscribe function in a state variable
        setUserUnsubscribe(() => userUnsubscribe);
      } else {
        // If no user is logged in, set the state accordingly
        setShowSelectedPositions([]);
        setSelectedPositions([]);
      }
    });

    // Fetch localOrders
    const localOrdersUnsubscribe = onValue(ordersRef, (snapshot) => {
      if (snapshot.exists()) {
        const ordersData = Object.entries(snapshot.val()).map(
          ([key, value]) => ({ key, ...value }),
        );
        setLocalOrders(ordersData);

        const tableNumbers = ordersData.map((order) => order.tableNumber);
        const uniqueTableNumbers = [...new Set(tableNumbers)];
        setAllTableNumbers(uniqueTableNumbers);

        const archivedOrdersData = ordersData.filter((order) => !order.called);
        setArchivedOrders(archivedOrdersData);
      } else {
        setLocalOrders([]);
        setAllTableNumbers([]);
        setArchivedOrders([]);
      }
    });

    // Fetch archivedOrders
    const archivedOrdersRef = ref(database, "orders");
    const archivedOrdersUnsubscribe = onValue(archivedOrdersRef, (snapshot) => {
      if (snapshot.exists()) {
        const archivedOrdersData = Object.entries(snapshot.val()).map(
          ([key, value]) => ({ key, ...value }),
        );
        setArchivedOrders(archivedOrdersData);
      } else {
        setArchivedOrders([]);
      }
    });

    // Fetch positions
    const positionsRef = ref(database, "positions");
    const selectedPositionsRef = ref(database, "users");

    const positionsUnsubscribe = onValue(positionsRef, (snapshot) => {
      if (snapshot.exists()) {
        const positionsData = Object.entries(snapshot.val()).map(
          ([key, value]) => ({ key, ...value }),
        );
        setPositions(positionsData);
      } else {
        setPositions([]);
      }
    });
    const selectedPositionsUnsubscribe = onValue(
      selectedPositionsRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const selectedPositionsData = Object.entries(snapshot.val()).map(
            ([key, value]) => ({ key, ...value }),
          );

          // Update the state with the selected positions
          const initialSelectedPositions = selectedPositionsData.map(
            (pos) => pos.position,
          );
          setShowSelectedPositions(initialSelectedPositions);
          setSelectedPositions(initialSelectedPositions);
        } else {
          // If no selected positions exist in the 'users' table, set the state accordingly
          setShowSelectedPositions([]);
          setSelectedPositions([]);
        }
      },
    );

    return () => {
      localOrdersUnsubscribe();
      archivedOrdersUnsubscribe();
      positionsUnsubscribe();
      selectedPositionsUnsubscribe();
      userUnsubscribe();
    };
  }, []);
  // Save selectedPositions to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(
      "selectedPositions",
      JSON.stringify(selectedPositions),
    );
  }, [selectedPositions]);

  // Effect to retrieve selectedPositions from localStorage on component mount
  useEffect(() => {
    const storedSelectedPositions = localStorage.getItem("selectedPositions");

    if (storedSelectedPositions) {
      const parsedSelectedPositions = JSON.parse(storedSelectedPositions);
      setShowSelectedPositions(parsedSelectedPositions);
      setSelectedPositions(parsedSelectedPositions);
    }
  }, []);

  // Effect to save selectedPositions to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(
      "selectedPositions",
      JSON.stringify(selectedPositions),
    );
  }, [selectedPositions]);

  const handleDeleteOrder = async (key) => {
    const deletedOrder = localOrders.find((order) => order.key === key);
    const updatedOrders = localOrders.map((order) =>
      order.key === key ? { ...order, called: false } : order,
    );

    // Get the current timestamp for archived time
    const archivedTime = new Date().toISOString();

    // Update the local state
    setLocalOrders(updatedOrders);

    // Check if there is a logged-in user before proceeding
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      console.error("No user is logged in.");
      return;
    }

    // Update the database with the new "archivedCallsTime" field
    const database = getDatabase();
    const orderRef = ref(database, `orders/${key}`);
    const updateData = {
      called: false,
      callTimes: null,
      archivedCallsTime: [
        ...(deletedOrder.archivedCallsTime || []),
        ...(deletedOrder.callTimes || []).map((callTime) => ({
          userId: user.uid,
          callTime,
          archivedTime,
        })),
      ],
    };

    // Update the order data
    try {
      await update(orderRef, updateData);

      // Move the deleted order to the archivedOrders state
      setArchivedOrders((prevArchivedOrders) => [
        ...prevArchivedOrders,
        { ...deletedOrder, called: false },
      ]);

      console.log("Order successfully updated.");
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const handleSetCalledFalse = (key) => {
    handleDeleteOrder(key);
  };

  const handleIncrementCounter = () => {
    setCounter((prevCounter) => prevCounter + 1);
  };

  const calledTables = localOrders
    .filter((order) => order.called)
    .map((order) => order.tableNumber);

    const handleTabChange = (tab) => {
      // Update activeTab state when a tab is clicked
      setActiveTab(tab);
    };
    const handleChangeIndex = (index) => {
      // Update activeTab state based on the index
      setActiveTab(index === 1 ? 'call' : 'archived');
    };
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
      };
  
      // Initial check for mobile
      handleResize();
  
      // Event listener for window resize
      window.addEventListener("resize", handleResize);
  
      // Cleanup on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);


    
    if (!isMobile) {
      // Render something else for non-mobile devices
      return (
        <main className="min-h-screen flex flex-col dark:bg-gray-800 bg-primaryLight-300">
          <Header />
      <div className="block md:flex mx-auto  md:gap-4 gap-2 flex-1  w-full px-2 md:px-6 mt-12 md:mt-14">
        {/* Left Content */}
        
        <section className="flex-1 bg-primaryLight-200 mb-4 pb-3 rounded-large shadow-md dark:bg-gray-700 ">
          <TablesByPosition
            showPositions={showSelectedPositions}
            allPositions={allPositions}
            handleTogglePosition={handleTogglePosition}
            handleToggleAllPositions={handleToggleAllPositions}
          />
        </section>

        {/* Right Content */}
        <section className=" rounded-large shadow-md bg-primaryLight-200 dark:bg-gray-700 justify-center block pb-2 mb-4 w-auto md:w-my300  ">
          <>
            <div className="flex space-x-2 rounded-t-large bg-stone-500 dark:bg-gray-600 justify-center items-center py-2 h-11 md:h-my55">
            <button
              className={`text-gray-200 text-xs md:text-base flex self-auto items-center py-2 px-4 ${
                activeTab === "call"
                  ? "text-gray-800 dark:text-gray-200 bg-primaryLight-300 dark:bg-gray-700 flex self-auto items-center rounded-large"
                  : ""
              }`}
              onClick={() => handleTabChange("call")}
            >
              Called Tables
              <svg
                className={`inline-block w-5 h-5 ml-2 ${
                  activeTab === "call" ? "dark:fill-gray-200 fill-gray-800" : " fill-primaryLight-300"
                }`}
                xmlns="http://www.w3.org/2000/svg"
                height="56"
                viewBox="0 0 56 56"
                width="56"
              >
                <path d="m9.4257 43.2461h37.1485c2.2263 0 3.5391-1.1484 3.5391-2.8359 0-2.3438-2.3673-4.4532-4.4063-6.5391-1.5469-1.6172-1.9688-4.9453-2.1328-7.6406-.1875-9-2.5547-15.211-8.8125-17.461-.8672-3.0469-3.3047-5.4843-6.75-5.4843-3.4688 0-5.8828 2.4374-6.7735 5.4843-6.2343 2.25-8.625 8.461-8.789 17.461-.1875 2.6953-.586 6.0234-2.1563 7.6406-2.0156 2.0859-4.4062 4.1953-4.4062 6.5391 0 1.6875 1.3359 2.8359 3.539 2.8359zm11.4375 3.1875c.2813 3.4219 3.1641 6.2812 7.1485 6.2812 3.9609 0 6.8437-2.8593 7.1484-6.2812z"></path>
              </svg>
            </button>

            <button
              className={`text-gray-200 text-xs md:text-base flex self-auto items-center py-2 px-4 ${
                activeTab === "archived"
                  ? "dark:text-gray-200 text-gray-800 bg-primaryLight-300 dark:bg-gray-700 flex self-auto items-center rounded-large"
                  : ""
              }`}
              onClick={() => handleTabChange("archived")}
            >
              Served Tables
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className={`w-5 h-5 ml-2 ${
                  activeTab === "archived" ? "dark:fill-gray-200 fill-gray-800" : " fill-primaryLight-300"
                }`}
              >
                <path
                  fillRule="evenodd"
                  d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>

            </div>
            <div className="md:px-2.5 px-1.5 overflow-y-auto max-h-my85">
              {activeTab === "call" && (
                <CalledTableTab
                  orders={localOrders}
                  selectedPositions={selectedPositions}
                  handleSetCalledFalse={handleSetCalledFalse}
                  handleIncrementCounter={handleIncrementCounter}
                  positions={positions}
                />
              )}
            </div>
            <div className="overflow-y-auto max-h-my85">
              {activeTab === "archived" && (
                <ArchivedOrders archivedOrders={archivedOrders} />
              )}
            </div>
          </>
        </section>
      </div>
        </main>
      );
    }

  return (
    <main className="min-h-screen flex flex-col dark:bg-gray-800  bg-primaryLight-300">
    <Header />
      <div className="block md:flex mx-auto  md:gap-4 gap-2 flex-1  w-full px-2 md:px-6 mt-12 md:mt-14">
        {/* Left Content */}
        <SwipeableViews
        index={activeTab}
        onChangeIndex={handleChangeIndex}
        enableMouseEvents
      >
        <section className="flex-1 bg-primaryLight-200 mb-4 pb-3 rounded-large shadow-md dark:bg-gray-700 ">
          <TablesByPosition
            showPositions={showSelectedPositions}
            allPositions={allPositions}
            handleTogglePosition={handleTogglePosition}
            handleToggleAllPositions={handleToggleAllPositions}
          />
        </section>

        {/* Right Content */}
        <section className=" rounded-large shadow-md bg-primaryLight-200 dark:bg-gray-700 justify-center block pb-2 mb-4 w-auto md:w-my300  ">
          <>
            <div className="flex space-x-2 rounded-t-large bg-stone-500 dark:bg-gray-600 justify-center items-center py-2 h-11 md:h-my55">
            <button
              className={`text-gray-200 text-xs md:text-base flex self-auto items-center py-2 px-4 ${
                activeTab === "call"
                  ? "text-gray-800 dark:text-gray-200 bg-primaryLight-300 dark:bg-gray-700 flex self-auto items-center rounded-large"
                  : ""
              }`}
              onClick={() => handleTabChange("call")}
            >
              Called Tables
              <svg
                className={`inline-block w-5 h-5 ml-2 ${
                  activeTab === "call" ? "dark:fill-gray-200 fill-gray-800" : " fill-primaryLight-300"
                }`}
                xmlns="http://www.w3.org/2000/svg"
                height="56"
                viewBox="0 0 56 56"
                width="56"
              >
                <path d="m9.4257 43.2461h37.1485c2.2263 0 3.5391-1.1484 3.5391-2.8359 0-2.3438-2.3673-4.4532-4.4063-6.5391-1.5469-1.6172-1.9688-4.9453-2.1328-7.6406-.1875-9-2.5547-15.211-8.8125-17.461-.8672-3.0469-3.3047-5.4843-6.75-5.4843-3.4688 0-5.8828 2.4374-6.7735 5.4843-6.2343 2.25-8.625 8.461-8.789 17.461-.1875 2.6953-.586 6.0234-2.1563 7.6406-2.0156 2.0859-4.4062 4.1953-4.4062 6.5391 0 1.6875 1.3359 2.8359 3.539 2.8359zm11.4375 3.1875c.2813 3.4219 3.1641 6.2812 7.1485 6.2812 3.9609 0 6.8437-2.8593 7.1484-6.2812z"></path>
              </svg>
            </button>

            <button
              className={`text-gray-200 text-xs md:text-base flex self-auto items-center py-2 px-4 ${
                activeTab === "archived"
                  ? "dark:text-gray-200 text-gray-800 bg-primaryLight-300 dark:bg-gray-700 flex self-auto items-center rounded-large"
                  : ""
              }`}
              onClick={() => handleTabChange("archived")}
            >
              Served Tables
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className={`w-5 h-5 ml-2 ${
                  activeTab === "archived" ? "dark:fill-gray-200 fill-gray-800" : " fill-primaryLight-300"
                }`}
              >
                <path
                  fillRule="evenodd"
                  d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>

            </div>
            <div className="md:px-2.5 px-1.5 overflow-y-auto max-h-my85">
              {activeTab === "call" && (
                <CalledTableTab
                  orders={localOrders}
                  selectedPositions={selectedPositions}
                  handleSetCalledFalse={handleSetCalledFalse}
                  handleIncrementCounter={handleIncrementCounter}
                  positions={positions}
                />
              )}
            </div>
            <div className="overflow-y-auto max-h-my85">
              {activeTab === "archived" && (
                <ArchivedOrders archivedOrders={archivedOrders} />
              )}
            </div>
          </>
        </section>
        </SwipeableViews>
      </div>
    </main>
  );
};

export default Waiter;
