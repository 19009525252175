// Import necessary components and libraries
import React, { useState, useEffect, Fragment } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { ref, get } from 'firebase/database';
import { auth, database } from '../../firebaseConfig';
import { BellIcon, CogIcon } from '@heroicons/react/solid'; // Import ChevronDownIcon from heroicons
import { Menu, Transition } from '@headlessui/react'; // Import Menu and Transition from headlessui
import logo from '../../assets/pome-logo-white1.svg';

// Define tabs array
const tabs = [
  { name: 'Waiters Page', to: '/', icon: BellIcon, current: false },
  //{ name: 'Settings', to: '/settings', icon: CogIcon, current: false },
  // Add more tabs as needed
];



const Header = () => {
  const [user, setUser] = useState(null);
  const [darkMode, setDarkMode] = useState(() => localStorage.getItem('darkMode') === 'true' ? true : false);
  const navigate = useNavigate();
  const location = useLocation();

  // Effect to check user authentication state and fetch user data
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        const userRef = ref(database, `users/${user.uid}`);
        const snapshot = await get(userRef);
        const userData = snapshot.val();
        setUser({ ...user, ...userData });
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  // Effect to toggle dark mode and persist preference
  useEffect(() => {
    document.body.classList.toggle('dark', darkMode);
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  // Handle logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log('User logged out successfully!');
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  // Toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode(prevMode => !prevMode);
  };

  // Render header
  return (
    <header className="flex top-0 left-0 w-full bg-primaryLight-300 text-white fixed dark:bg-gray-800 px-2 md:px-6 z-50">
      {/* Left Column */}
      <div className="w-5/12 md:w-1/3 p-1 flex justify-start">
        <nav className="flex items-center md:text-base text-font0">
          {tabs.map((tab, index) => (
            <Link
              key={index}
              to={tab.to}
              className={`${location.pathname === tab.to ? 'text-stone-800 dark:text-gray-200 dark:bg-gray-700 bg-primaryLight-200  rounded-full' : 'text-gray-600 dark:text-gray-100'} hover:text-stone-800 flex items-center space-x-0 md:space-x-2 dark:hover:bg-gray-700 hover:bg-primaryLight-200 md:px-4 md:mr-2 px-2 py-2 rounded-full`}
            >
              <span>{tab.name}</span>
              <tab.icon className="w-6 h-6" />
            </Link>
          ))}
        </nav>
      </div>
      {/* Middle Column */}
      <div className="w-2/12 md:w-1/3 p-1 md:flex justify-center grid content-center">
        <img src={logo} alt="Logo" className="invert dark:invert-0 md:w-40 md:h-full object-left w-8 h-9 object-cover md:object-contain" />
      </div>
      {/* Right Column */}
      <div className="w-5/12 md:w-1/3 p-1 flex justify-end text-xs md:text-base ">
        {user && (
          <div className=" ">
            <div className="hidden md:flex items-center justify-between">
              <span className="inline-block w-6 h-6 mr-1 rounded-full " style={{ backgroundColor: user.color }}></span>
              <span className="text-gray-600 dark:text-gray-100 md:text-sm">{user.username} {user.surname}!</span>
              <button className="dark:hover:bg-gray-700 hover:bg-gray-200 text-gray-600 dark:text-gray-100 py-1 md:py-3 px-4 rounded-full text-sm ml-2 font-normal" onClick={handleLogout}>Logout</button>
              {/* Dark Mode Toggle Button */}
              <button onClick={toggleDarkMode} className="h-8 w-8 md:h-12 md:w-12 rounded-full p-0 md:p-2 hover:bg-gray-100 dark:hover:bg-gray-700">
                <svg className="fill-violet-700 block dark:hidden" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                </svg>
                <svg className="fill-yellow-500 hidden dark:block" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd"></path>
                </svg>
              </button>
            </div>
            {/* Integrated Menu Component */}
            <Menu as="div" className="relative inline-block text-left md:hidden">
              <div>
                <Menu.Button className="inline-flex w-full justify-center rounded-full p-2 ring-gray-300 hover:bg-primaryLight-200 dark:hover:bg-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  className="w-6 h-6  fill-gray-700 dark:fill-primaryLight-200">
                  <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clipRule="evenodd" />
                </svg>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-1 w-max origin-top-right rounded-xl bg-gray-100 text-white dark:bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="p-4 gap-y-4 grid">
                    <Menu.Item>
                      {({ active }) => (
                        <div className="flex items-center">
                          <span className=" w-5 h-5 ml-1 mr-1 rounded-full " style={{ backgroundColor: user.color }}></span>
                          <span className="text-gray-600 dark:text-gray-100 text-xs">{user.username} {user.surname}!</span>
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div className='flex place-content-between'>
                          <button onClick={toggleDarkMode} className="h-7 w-7 rounded-full p-1 bg-gray-200 hover:bg-gray-300 dark:hover:bg-gray-700 dark:bg-gray-600">
                            <svg className="fill-violet-700 block dark:hidden" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                            </svg>
                            <svg className="fill-yellow-500 hidden dark:block" fill="currentColor" viewBox="0 0 20 20">
                              <path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd"></path>
                            </svg>
                          </button>
                          <button className="dark:bg-gray-600 dark:hover:bg-gray-700 bg-gray-200 hover:bg-gray-300 text-gray-600 dark:text-gray-100 py-1 px-4 rounded-full text-xs ml-2 " onClick={handleLogout}>Logout</button>
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
