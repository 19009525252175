// DeleteArchivedCallsButton.js
import React from 'react';
import { get, ref, update, getDatabase } from 'firebase/database';

const DeleteArchivedCallsButton = () => {
  const handleDeleteArchivedCalls = async () => {
    const database = getDatabase();
    const ordersRef = ref(database, 'orders');

    try {
      // Fetch all orders
      const snapshot = await get(ordersRef);

      // Update each order to remove the archivedCallsTime field
      if (snapshot.exists()) {
        const ordersData = snapshot.val();
        const orderKeys = Object.keys(ordersData);

        for (const key of orderKeys) {
          const orderRef = ref(database, `orders/${key}`);
          await update(orderRef, { archivedCallsTime: null });
        }

        console.log('All archivedCallsTime entries deleted successfully.');
      }
    } catch (error) {
      console.error('Error deleting archivedCallsTime entries:', error);
    }
  };

  return (
    <button onClick={handleDeleteArchivedCalls}>
      Delete All Archived Calls Time
    </button>
  );
};

export default DeleteArchivedCallsButton;
