// SingleTablesPage.js
import React, { useEffect, useState } from "react";
import { getDatabase, ref, get, update, onValue } from "firebase/database";
import { useParams } from "react-router-dom";
import "./SingleTables.css";
import ErrorPage from "../components/ErrorPage";

const SingleTablesPage = () => {
  const { tableNumberHash } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const database = getDatabase();
    const orderRef = ref(database, `orders/${tableNumberHash}`);
  
    const handleValue = (snapshot) => {
      if (snapshot.exists()) {
        setOrder({
          key: snapshot.key,
          ...snapshot.val(),
        });
      } else {
        console.error("Order not found1");
      }
      setLoading(false);
    };
  
    const handleError = (error) => {
      console.error("Error fetching order:", error.message);
      setLoading(false);
    };
  
    const unsubscribe = onValue(orderRef, handleValue, { errorCallback: handleError });
  
    // Cleanup function
    return () => {
      // Unsubscribe from the onValue listener
      unsubscribe();
    };
  }, [tableNumberHash]);

  const handleCallWaiter = async () => {
    if (!order) {
      console.error("Order not found");
      return;
    }
  
    const database = getDatabase();
    const updatedOrderRef = ref(database, `orders/${tableNumberHash}`);
  
    try {
      // Update the order in the database
      await update(updatedOrderRef, {
        called: true,
        callTimes: [...(order.callTimes || []), new Date().toISOString()],
        // Add other properties as needed
      });
  
      // Fetch the updated order directly without additional request
      const updatedOrderSnapshot = await get(updatedOrderRef);
      if (updatedOrderSnapshot.exists()) {
        const updatedOrderData = {
          key: updatedOrderSnapshot.key,
          ...updatedOrderSnapshot.val(),
        };
  
        // Update the state with the updated order
        setOrder(updatedOrderData);
        setMessage("Waiter has been called!");
  
        // Redirect to the specified URL after 3 seconds
        setTimeout(() => {
          window.location.href = "https://pome.eqr.gr/jason/";
        }, 3000); // 3000 milliseconds = 3 seconds
      }
    } catch (error) {
      console.error("Error calling waiter:", error.message);
    }
  };

  if (loading) {
    return <div className='  dark:bg-gray-700 h-screen text-white place-content-center items-center flex'>Loading...</div>;
  }

  if (!order) {
    return <ErrorPage errorMessage={message} />;
  }

  return (
    <section className="TablePage-container flex flex-col items-center justify-top h-screen bg-gray-600 bg-cover bg-center bg-no-repeat relative pt-32">

      <div className="text-gray-200 text-7xl font-bold">{order.tableNumber}</div>
      <div className="text-gray-200 text-4xl font-medium pt-4">Table</div>

      <button
        className="call-button cursor-pointer bg-red-600 hover:bg-green-700 text-gray-200 border-solid border-my10 border-text-gray-200 rounded-full text-2xl mt-10 h-52 w-52"
        onClick={handleCallWaiter}
      >
        <svg className=" fill-stone-200 w-full h-14 mb-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" >
          <path  d="m9.4257 43.2461h37.1485c2.2263 0 3.5391-1.1484 3.5391-2.8359 0-2.3438-2.3673-4.4532-4.4063-6.5391-1.5469-1.6172-1.9688-4.9453-2.1328-7.6406-.1875-9-2.5547-15.211-8.8125-17.461-.8672-3.0469-3.3047-5.4843-6.75-5.4843-3.4688 0-5.8828 2.4374-6.7735 5.4843-6.2343 2.25-8.625 8.461-8.789 17.461-.1875 2.6953-.586 6.0234-2.1563 7.6406-2.0156 2.0859-4.4062 4.1953-4.4062 6.5391 0 1.6875 1.3359 2.8359 3.539 2.8359zm11.4375 3.1875c.2813 3.4219 3.1641 6.2812 7.1485 6.2812 3.9609 0 6.8437-2.8593 7.1484-6.2812z"></path>
        </svg>
        Call a Waiter
      </button>
      {message && (
        <div className="text-2xl font-normal text-gray-200 flex items-center justify-center text-center mt-10">
          <svg className="stroke-2 fill-stone-200 p-0.5 w-6 bg-green-700 rounded-full mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd" />
          </svg>
          {message}
        </div>
      )}
    </section>
  );
};

export default SingleTablesPage;
