// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyA42elJs5jrWKOq5SBXP4PcRPeUqe1S68U",
    authDomain: "test-10347.firebaseapp.com",
    databaseURL: "https://test-10347-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "test-10347",
    storageBucket: "test-10347.appspot.com",
    messagingSenderId: "30130834791",
    appId: "1:30130834791:web:13a2bafcb8e9bbab36f3c2"
  };
  
const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);
const auth = getAuth(firebaseApp);
const firestore = getFirestore(firebaseApp);
const messaging = getMessaging(firebaseApp);

// Function to fetch positions from Firebase
export const getPositions = async () => {
  const positionsRef = ref(database, 'positions');
  const positionsSnapshot = await get(positionsRef);

  if (positionsSnapshot.exists()) {
    return Object.entries(positionsSnapshot.val()).map(([key, value]) => ({
      key,
      ...value,
    }));
  } else {
    return [];
  }
};

export { firebaseConfig, firebaseApp, database, auth ,firestore, messaging  };